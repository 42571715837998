<template>
   <div>导出</div>
</template>

<script>
export default {
  name: "inList"
}
</script>

<style scoped>

</style>